@media screen and (max-width: 991px) {
    .hero .hero-main_1 h1 {
        font-size: 30px;
    }
    
    .Developer {
        font-size: 30px;
    }

    .design_2 h4 {
        font-size: 30px;
    }

    .Relaxioo .Relaxioo-main h2 {
        font-size: 30px;
    }

    .purpose .shape:first-child {
        margin-top: 0px;
    }

    .UserFeatures .Discover .Shuffle-Mode.right {
        text-align: left;
    }
    .mUSIC{
        font-size: 30px;
    }
    .hide-div{
        display: none !important    ;
    }
}

@media screen and (max-width: 767px) {
    .hero .hero-main_1 h1 {
        font-size: 25px;
    }
    .margin_top{
        display: none;
    }
    .footer{
        border-radius: 0px;
    }

    .Developer {
        font-size: 25px;
    }

    .design_2 h4 {
        font-size: 25px;
    }

    .hero .hero-main_1 h4 {
        font-size: 25px;
    }

    .hero .hero-main_1 .animation_wrapper .animation_inner h3 {
        font-size: 25px;
    }

    .portfolio .tabs-main .mobile h3 {
        font-size: 25px;
    }

    .Relaxioo .Relaxioo-main h2 {
        font-size: 25px;
    }

    .purpose .shape {
        margin-top: 20px;
    }

    .UserFeatures {
        padding: 50px 0px;
    }

    .about-page .about-main h3 {
        font-size: 25px;
    }
    .mUSIC{
        font-size: 25px;
    }

}

@media screen and (max-width: 575px) {
    .award .award-sub {
        height: 0px;
    }

    .subrow .sub-demo-5 {
        grid-column: span 2;
        grid-row: span 2;
    }
    .subrow .sub-demo{
        grid-row: span 2;
        grid-column: span 2;
    }
    .main-figure{
        grid-column: span 2;
    }
   
}

@media screen and (max-width: 1199px) {}