@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  background-color: var(---background-color);
  background-image: url(../src/Img/Background_Design-NEW.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  object-fit: cover;
  width: 100%;
}

a:hover {
  text-decoration: none;
  color: none;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins', sans-serif;
}

p {
  font-family: "Poppins", sans-serif;
}

.card {
  font-family: "Poppins", sans-serif;
}

:root {
  --white: #ffff;
  --main-color: #D14E51;
  ---black: #192734;
  ---background-color: #F5F5F5;
  --text-color: #000000;
  --text-color-2: #6F6F6F;
  --text-color-3: #5B5B5B;

}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  background-color: var(--main-color);
}

.navbar-toggler {
  border-color: var(--main-color);
}

.navbar-toggler:focus {
  box-shadow: none;
}

.mUSIC {
  font-weight: 600;
  font-size: 40px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(---black);
  margin: auto;
  width: 70%;
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: var(--main-color);
  transform-origin: 0%;
  z-index: 1031;
}

.loader {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.styles-module_chatHeader__gaeOA .styles-module_statusTitle__NgfPQ {
  font-size: 15px !important;
}

/* ================Header=============  */
.header {
  background-color: var(---background-color);
  border-bottom: 1px solid #C33F42;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.11);
  margin-bottom: 60px
}

.fixed-top {
  background-color: var(---background-color);
  border-bottom: 1px solid #C33F42;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.11);
}

.header .nav-link {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.1em;
  color: var(--text-color-2);
  transition: all .4s ease;
  position: relative;
}

.header .nav-link:hover::before {
  width: 24px;
}

.header .nav-link:hover {
  color: var(--main-color);
}

.header .nav-link::before {
  height: 3px;
  border-radius: 25px;
  display: inline-block;
  position: absolute;
  content: '';
  width: 0px;
  background-color: var(--main-color);
  bottom: 0px;
  transition: all .4s ease;
}

.sub-dropdown {
  text-decoration: none;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.02em;
  color: var(---black);
  transition: all .4s ease;
}

.sub-dropdown:hover {
  color: var(--main-color);
}

.dropdown-item.active,
.dropdown-item:active {
  background: none !important;
  color: var(---black);
}

.header .bgnone {
  background: none;
  border: none;
}

.sidebar-main {
  padding: 20px 20px;
}

.sidebar-main .latest {
  font-weight: 400;
  font-size: 15px;
  color: var(--text-color-3);
  margin-top: 20px;
}

.sidebar-main .ourServices {
  padding: 20px 0px;
}

.sidebar-main .ourServices h4 {
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  color: var(---black)
}

.sidebar-main .ourServices ul {
  list-style: none;
  padding: 0px;
}

.sidebar-main .ourServices ul li {
  font-weight: 400;
  font-size: 15px;
  letter-spacing: -0.01em;
  color: var(--text-color-3);
  padding: 5px 0px;
}

/* ================Hero=============  */
.hero {
  padding: 50px 0px;
}

.hero .hero-main_1 h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 126%;
  text-transform: uppercase;
  color: var(--main-color);
  margin-bottom: 15px;
}

.hero .hero-main_1 h1 {
  font-weight: 600;
  font-size: 40px;
  line-height: 114.5%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(---black);
}

.hero .hero-main_1 h4 {
  font-weight: 800;
  font-size: 30px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: transparent;
  -webkit-text-stroke: 1.5px var(---black);
  display: inline-block;
  margin-bottom: 0px;
  height: 42px;
}

.hero .hero-main_1 .animation_wrapper {
  display: flex;
  align-items: center;
}

.hero .hero-main_1 .animation_wrapper b {
  overflow: hidden;
  position: relative;
  height: 42px;
}

.hero .hero-main_1 .animation_wrapper .animation_inner h3 {
  height: 42px;
  font-weight: 800;
  font-size: 30px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.hero .hero-main_1 .animation_wrapper .animation_inner {
  display: inline-block;
  color: var(--main-color);
  position: relative;
  white-space: nowrap;
  top: 0px;
  left: 0;
  -webkit-animation: move 7s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: 3;
}

@keyframes move {
  0% {
    top: 0px;
  }

  20% {
    top: -45px;
  }

  40% {
    top: -95px;
  }

  60% {
    top: -155px;
  }

  /* 80% {
    top: -236px;
  } */
}

.middle {
  color: var(--white);
  padding-left: 5px;
  transition: all .4s ease;
}

.hero .hero-main_2 {
  position: relative;
}

.hero .hero-main_2 .innerimg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 88%;

}

/* ================focus=============  */
.Focus .homeinfo {
  list-style: none;
  justify-content: center;
  padding: 0px;
  flex-wrap: wrap;
}

.Focus .homeinfo li {
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  color: var(---black);
  padding: 0px 20px;
}

.Focus .homeinfo li+li {
  border-left: 3px solid var(--text-color);
}

.Focus .homeinfo li marquee {
  vertical-align: middle;
}

.Focus .homeinfo .marquee {
  width: min-content;
}

.Focus .homeinfo .overlay::before,
.overlay::after {
  background: none !important;
}

/* ================experience=============  */
.experience {
  padding: 50px 0px;

}

.design span {
  width: 63px;
  border-radius: 14px;
  height: 4px;
  background: var(--main-color);
  display: inline-block;
  position: relative;
}

.design span::after {
  content: '';
  position: absolute;
  width: 10px;
  height: 4px;
  background: var(--main-color);
  right: -20px;
  border-radius: 14px;
}

.design h3 {
  font-weight: 600;
  font-size: 20px;
  color: var(---black);
  text-transform: uppercase;
  padding-top: 10px;
}

.Developer {
  font-weight: 600;
  font-size: 40px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(---black);

}

.Top-rated {
  font-weight: 500;
  font-size: 15px;
  color: var(--main-color);
}

.experience .experience-main_2 h4 {
  color: transparent;
  -webkit-text-stroke: 1.5px var(---black);
  font-weight: 800;
  font-size: 30px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.experience .experience-main_2 h4 span {
  color: var(--main-color);
  -webkit-text-stroke: 0px;
}

.experience .experience-main_2 p {
  font-weight: 400;
  font-size: 15px;
  color: var(--text-color-3);
}

.experience .experience-main_2 .choose h5 {
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 0.02em;
  color: var(--main-color);
}

.experience .experience-main_2 .choose ul {
  list-style: none;
  padding: 0px;
}

.experience .experience-main_2 .choose ul li span {
  font-weight: 400;
  font-size: 15px;
  color: var(--text-color-3);
  padding-left: 7px;
}

.Explore {
  background: linear-gradient(269.89deg, #CA4649 0.07%, #E86769 101.14%);
  border-radius: 2px;
  border: none;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  color: var(--white);
  padding: 10px 15px;
  transition-duration: 0.5s;
  border: 1px solid var(--main-color);

}

.Explore:hover {
  background: var(--white);
  color: var(--main-color);
  border: 1px solid var(--main-color);
}

.Explore:hover .middle {
  color: var(--main-color);
}

/* ================award=============  */
.award {
  padding: 50px 0px;
  background-image: url(./Img/award.png);
  background-position: center !important;
  background-repeat: no-repeat;
  background-size: cover;

}

.award .happy {
  position: relative;
  width: 250px;
  height: 250px;
  margin: auto;
  overflow: hidden;
}

.award .award-sub {
  height: 150px;
}

.award .happy .happy-client {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  transform: rotate(-30deg) skewX(30deg) scaleY(.866);
  border-radius: 5px;

}

.award .happy .happy-client:before {
  position: absolute;
  right: 6.7%;
  bottom: 0;
  left: 6.7%;
  top: 0;
  transform: scaleY(1.156) skewX(-30deg) rotate(30deg);
  background: var(--white);
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.11);
  content: '';
  border-radius: 25px;
}

.award .happy .design-box {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
}

.award .happy .design-box h3 {
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(---black);
}

.award .happy .design-box p {
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  letter-spacing: 0.02em;
  color: var(--text-color-3);
}

/* ================portfolio=============  */
.portfolio {
  padding: 50px 0px;
  position: relative;
}


.design_2 {
  text-align: center;
}

.design_2 span {
  width: 63px;
  border-radius: 14px;
  height: 4px;
  background: var(--main-color);
  display: inline-block;
  position: relative;
  margin-bottom: 20px;
}

.design_2 span::after {
  content: '';
  position: absolute;
  width: 10px;
  height: 4px;
  background: var(--main-color);
  right: -20px;
  border-radius: 14px;
}

.design_2 span::before {
  content: '';
  position: absolute;
  width: 10px;
  height: 4px;
  background: var(--main-color);
  left: -20px;
  border-radius: 14px;
}

.design_2 h3 {
  font-weight: 600;
  font-size: 20px;
  color: var(---black);
  text-transform: uppercase;
  padding-top: 10px;
  transition-duration: 0.5s;
  cursor: pointer;
}

.design_2 h3:hover {
  color: var(--main-color);
}

.design_2 h4 {
  font-weight: 600;
  font-size: 40px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(---black);
}

.design_2 p {
  font-weight: 500;
  font-size: 15px;
  color: var(--main-color);
  text-align: center;
}

.portfolio .tabs-main {
  padding-top: 40px;
}

.portfolio .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background: rgba(209, 78, 81, 0.15);
  color: var(--main-color);
  border-left: 8px solid var(--main-color);

}

.portfolio .nav-pills .nav-link {
  font-weight: 500;
  font-size: 15px;
  text-transform: uppercase;
  color: var(--text-color-3);
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.portfolio .tabs-main .mobile h3 {
  font-weight: 500;
  font-size: 30px;
  text-transform: uppercase;
  color: var(---black);
}

.portfolio .tabs-main .mobile p {
  font-weight: 400;
  font-size: 15px;
  color: var(--text-color-3);
}

.portfolio .tabs-main .mobile .part-div {
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--main-color);
}


.portfolio .tabs-main .mobile ul {
  list-style: none;
  padding: 0px;
}

.portfolio .tabs-main .mobile ul li span {
  padding-left: 7px;
  font-weight: 400;
  font-size: 15px;
  color: var(---black);
}

.portfolio .tabs-main .mobile ul li svg path {
  fill: var(---black);
}

/* ================services=============  */
.services {
  padding: 50px 0px;
  position: relative;
}


.services .creative-idea {
  border: 1.5px solid var(--main-color);
  padding: 20px 20px;
}

.services .creative-idea .creative-icon {
  position: relative;
  margin-bottom: 30px;
}

.services .creative-idea .creative-icon span {
  font-weight: 700;
  font-size: 128px;
  position: absolute;
  top: 0;
  left: 15px;
  z-index: -1;
  line-height: 83.5%;
  -webkit-text-stroke: 2px rgba(209, 78, 81, 0.1);
  color: transparent;
}

.services .creative-idea h3 {
  font-weight: 600;
  font-size: 23px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(---black);
  transition: all .4s ease;
}

.services .creative-idea h3:hover {
  color: var(--main-color);
}

.services .creative-idea p {
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.02em;
  color: var(--text-color-3);
}

/* ================skills=============  */
.skills {
  padding: 50px 0px;

}


.skills .skills-tab {
  padding-top: 50px;
}

.skills .skills-tab .front-main {
  display: flex;
  justify-content: center;
}

.skills .skills-tab .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background: none;
  color: var(---black);
  font-weight: 700;
  border-color: var(--main-color);
}

.skills .skills-tab .nav-pills .nav-link {
  letter-spacing: 0.02em;
  color: var(--text-color-3);
  font-size: 15px;
  font-weight: 600;
  border-bottom: 4px solid transparent;
}

.skills .skills-tab .first-img {
  width: 100%;
  position: relative;
  height: 80px;
}

.skills .skills-tab .first-img img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.skills .skills-tab .list-main {
  list-style: none;
  padding: 0px;
  display: inline-flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  flex-wrap: wrap;
}

.skills .skills-tab .list-main li {
  background: transparent;
  text-align: center;
  margin: 0 17px;
  padding: 10px;
  flex-wrap: wrap;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 120px;
}

.skills .skills-tab .list-main li h3 {
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  letter-spacing: 0.02em;
  color: var(---black);
  margin-top: 15px;
}

/* ================purpose=============  */
.purpose {
  padding: 50px 0px;
  position: relative;
}

.purpose .shape .Discover:first-child {
  margin-top: 0px;
}

.purpose .shape .Discover {
  margin-top: 20px;
}

.purpose .shape .Discover {
  padding: 30px 20px;
  background-color: var(--white);
  border-radius: 3px;
  border: 1.5px solid var(--main-color);
  position: relative;
  min-height: 220px;
  transition: all .4s ease;
}

.purpose .shape .Discover:hover {
  border-color: var(---black);
}

.purpose .shape .Discover h5 {
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 0.02em;
  color: var(--main-color);
}

.purpose .shape:first-child {
  margin-top: 30px;
}

/* ================Footer=============  */
.footer {
  margin-top: 50px;
  background: rgba(209, 78, 81, 0.15);
  border-radius: 204px 0px 0px 0px;
  padding-top: 50px;
}

.footer .footer-main {
  text-align: center;
}

.Evenmorelogo {
  display: block;
  margin: auto;
  margin-bottom: 20px;
}

.footer .footer-main p {
  font-weight: 400;
  font-size: 15px;
  color: var(--text-color-3);
  text-align: center;
}

.footer .footer-main .address {
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  color: var(---black);
  margin-bottom: 0px;
}

.footer .footer-main .info {
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  color: var(---black);
  margin-bottom: 0px;
}

.margin_top {
  margin-top: -90px;
  text-align: center;
}

.footer .footer-bottom-main {
  border-top: 1px solid #D1D1D1;
  padding: 20px 0;
}

.footer .footer-bottom-main .copy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer .footer-bottom-main .copy .copy-sub h2 {
  font-weight: 500;
  font-size: 15px;
  color: var(---black);
  margin-bottom: 0px;
}

.icon-footer ul {
  list-style: none;
  padding: 0px;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}

.icon-footer ul li {
  padding-left: 10px !important;
}

.icon-footer ul li:first-child {
  padding-left: 0px !important;
}

/* ================about-page=============  */
.about-page {
  padding: 50px 0px;
  background-image: url('./Img/about-bg.png');
  background-repeat: no-repeat;
  z-index: 0;
  min-height: 540px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.about-main {
  text-align: center;
}

.sub-demo .page-section {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--main-color);
  text-decoration: none;
  transition-duration: 0.5s;

}

.sub-demo .page-section:hover {
  color: var(---black)
}

.sub-demo span {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--main-color);
  text-decoration: none;
}

.about-page .about-main h3 {
  font-weight: 600;
  font-size: 47px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(---black);
}

/* ================Core=============  */
.core {
  padding: 50px 0px;
}

.core-bg {
  background-image: url(./Img/core-img.png);
  width: 100%;
  min-height: 370px;
  border-radius: 0px 274.5px 274.5px 0px;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
}

.core-bg img {
  max-width: 100%;
}

.top-margin {
  margin-top: -45px;
}

/* ================sub category=============  */
.sub-category {
  padding: 50px 0px;
}

.sub-category .ui-ux {
  background-color: var(--white);
  border: 1.5px solid #D1D1D1;
  padding: 25px 12px 12px 25px;
  min-height: 280px;
}

.sub-category .ui-ux h3 {
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(---black);
}

.sub-category .ui-ux ul {
  padding: 0;
  list-style: none;
}

.sub-category .ui-ux ul li {
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.02em;
  color: var(--text-color-3);
}

/* ================four=============  */
.four {
  padding: 50px 0px;
}

.four .Our-Mission {
  display: flex;
  margin-bottom: 25px;
}

.four .Our-Mission .Our-Mission-img {
  margin-right: 20px;
}

.four .Our-Mission .OurMission-sub h5 {
  font-weight: 600;
  font-size: 23px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--main-color);
}

.four .Our-Mission .OurMission-sub p {
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.02em;
  color: var(--text-color-3);

}

/* ================Relaxioo-page=============  */
.Relaxioo {
  padding: 50px 0px;
  background-image: url('./Img/about-bg.png');
  background-repeat: no-repeat;
  z-index: 0;
  min-height: 540px;
}

.Relaxioo .Relaxioo-main h2 {
  font-weight: 600;
  font-size: 40px;
  letter-spacing: 0.02em;
  color: var(---black);
  margin-bottom: 20px;

}

.view-demo span {
  vertical-align: text-bottom;
}

/* ================Simplest=============  */
.Simplest {
  padding: 50px 0px;
}

.Simplest .user {
  background-color: var(--white);
  border: 1.5px solid #D14E51;
  padding: 20px 15px 20px 15px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Simplest .user img {
  width: 80px;
  height: 80px;
}

.Simplest .user h3 {
  font-weight: 600;
  font-size: 23px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(---black);
}

.Simplest .user p {
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.02em;
  color: var(--text-color-3);
}

/* ================ExtraFacility=============  */
.ExtraFacility {
  padding: 60px 0px;
  background-color: #F8E4E5;
}

.ExtraFacility .Fully-responsive {
  background-color: var(--white);
  padding: 30px;

}

.ExtraFacility .Fully-responsive ul {
  list-style: none;
  padding: 0px;
}

.ExtraFacility .Fully-responsive ul li span {
  font-weight: 400;
  font-size: 15px;
  color: var(--text-color-3);
  padding-left: 10px;
}

/* ================ExtraFacility=============  */
.UserFeatures {
  padding: 100px 0px;
  position: relative;
  overflow: hidden;
}

.round-shape {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%)
}

.round-shape span {
  border: 1px dashed rgba(209, 78, 81, 0.33);
  display: block;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.round-shape span:first-child {
  width: 400px;
  height: 400px;
}

.round-shape span:nth-child(2) {
  width: 500px;
  height: 500px;
}

.round-shape span:nth-child(3) {
  width: 600px;
  height: 600px;
}

.round-shape span:nth-child(4) {
  width: 700px;
  height: 700px;
}

.round-shape span:nth-child(5) {
  width: 7px;
  height: 7px;
  background-color: #47298D;
  animation: scale-up 2s infinite;
}

.bg-color {
  background-color: #4CB8C4 !important;
}

.bg-color-2 {
  background-color: #6F61E7 !important;
}

.bg-color-3 {
  background-color: #146BB2 !important;
}

@keyframes scale-up {
  90% {
    transform: scale(100);
    opacity: 0;
  }

  99% {
    transform: scale(1);
    opacity: 0;
  }
}

.UserFeatures .Discover .Shuffle-Mode {
  margin: 15px 0px;
}

.UserFeatures .Discover .Shuffle-Mode h3 {
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(---black);
}

.UserFeatures .Discover .Shuffle-Mode p {
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.02em;
  color: var(--text-color-3);
}

.UserFeatures .Discover .Shuffle-Mode.right {
  text-align: right;

}

.img-center {
  text-align: center;
  margin: auto;
}

.Discover img {
  max-width: 100%;
}

/* ================Mobile=============  */
.Mobile {
  padding: 50px 0px;

}

.swiper {
  overflow-x: clip !important;
}

.slider-img {
  width: 100%;
}

.slider-img img {
  width: 100%;
}

.swiper-slide-active {
  transform: scale(1);
  transition: .4s;
}

/* ================clientPortfolio-page=============  */
.products {
  padding: 50px 0px;
}

.products .portfolioitem .clients img {
  max-height: 266px;
}

.products .portfolioitem .clients-info {
  position: relative;
}

.products .portfolioitem .clients-info .portfolio-main {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--white);
  bottom: 0px;
  left: 30px;
  right: 60px;
  opacity: 0;
  transition: all .50s ease-in-out;
}

.products .portfolioitem {
  transition: all .50s ease-in-out;
}

.products .portfolioitem:hover .portfolio-main {
  bottom: 25px;
  opacity: 1;
}

.products .portfolioitem .clients-info .info-sub {
  padding: 15px
}

.products .portfolioitem .clients-info .info-sub h4 {
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.02em;
  color: var(---black);
}

.products .portfolioitem .clients-info .info-sub p {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.02em;
  color: var(--text-color-3);
  margin-bottom: 0px;
  white-space: break-spaces;
}

.products .portfolioitem .clients-info .info-sub-2 .quality {
  background-color: #9A1315;
  display: inline-block;
  border: none;
  position: absolute;
  height: 80%;
  top: 9px;
  right: -9%;
  padding: 0px 25px;
  border: none;

}

.quality-2 {
  background-color: #10483D !important;

}

.quality-3 {
  background-color: #4042E2 !important;
}

.quality-4 {
  background-color: #F1BB00 !important;
}

.quality-5 {
  background-color: #000000 !important;
}

.quality-6 {
  background-color: #0E718B !important;
}

.quality-7 {
  background-color: #0064A9 !important;
}

.quality-8 {
  background-color: #7486DB !important;
}

/* ================Activitiesimage=============  */
.Activitiesimage {
  padding: 50px 0px;
}

.Activitiesimage .subrow {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: 200px;
  grid-auto-flow: dense;

}

figure {
  margin: 0px !important;
}

.subrow>.main-figure {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;

}

.subrow>.main-figure img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  display: inline-block;
}

.subrow>.main-figure>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.subrow .sub-demo {
  grid-row: span 2;
}

.subrow>.sub-demo-2 {
  grid-column: span 2;
  grid-row: span 2;
}

.subrow>.sub-demo-4 {
  grid-column: span 2;
}

.subrow .sub-demo-5 {
  grid-column: span 2;
  grid-row: span 2;
}

/* ================JobPositions page=============  */
.JobPositions {
  padding: 50px 0px;
}

.sub-position {
  padding-top: 50px;
}

.mb {
  margin-bottom: 30px;
}

.main-div-position {
  border: 1.5px solid #D1D1D1;
  background-color: var(--white);
  padding: 40px 25px;
  height: 100%;
  transition: all .4s ease;
}

.main-div-position h3 {
  font-weight: 600;
  font-size: 23px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(---black);
  margin-bottom: 15px;
}

.main-div-position p {
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.02em;
  color: var(---black);
  cursor: pointer;
}

.main-div-position ul {
  list-style: none;
  padding: 0px;
  margin-bottom: 20px
}

.main-div-position ul li {
  display: inline-block;
}

.main-div-position ul li span {
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.02em;
  color: var(--text-color-3);
  padding: 0px 10px;
}

.main-div-position img {
  transition: transform .7s ease-in-out;
}

.main-div-position img:hover {
  transform: rotate(360deg);
}

/* ================ourculture=============  */
.ourculture {
  padding: 50px 0px;
  background-color: rgba(209, 78, 81, 0.15);
}

.ourculture-info {
  text-align: center;
  margin-bottom: 30px;
}

.ourculture-info img {
  max-width: 108px;
  transition: transform .7s ease-in-out;
}

.ourculture-info img:hover {
  transform: rotate(360deg);
}

.ourculture-info h3 {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(---black);
  padding-top: 20px;
}

/* ================ComeJoin=============  */
.ComeJoin {
  padding: 50px 0px;
}

.modal-dialog {
  max-width: 70%;
  margin: auto;
}

.modal-footer {
  justify-content: center;
}

.modal-title {
  font-weight: 600;
  font-size: 23px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(---black);
}

.modal-content {
  padding: 30px 20px;
}

.form-label {
  font-weight: 400;
  font-size: 15px;
  color: var(--text-color-3);
}

.form-control {
  background: #FFFFFF;
  border: 1.5px solid #D1D1D1
}

::-webkit-input-placeholder {
  color: #969595 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.resume {
  padding: 20px 30px;
  border: 1.5px dashed #D1D1D1;
  background-color: var(--white);
}

/* ================ClientsSay=============  */
.ClientsSay {
  padding: 50px 0px;
}

.carousel-indicators {
  margin-bottom: 0px;
}

.carousel-inner {
  margin: 30px 0px;
}

.sub-data {
  text-align: center;
}

.sub-data h3 {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: var(---black);
}

.sub-data p {
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  color: var(--text-color-3);
}

.carousel-control-next-icon {
  background-image: url(./Img/next.png);
}

.carousel-control-prev-icon {
  background-image: url(./Img/prev.png);
}

/* ================clientsLogo=============  */
.clientsLogo {
  padding: 50px 0px;
}

.clientsLogo .logo-img img {
  width: 100%;
}

.clientsLogo .logo-img {
  width: 100%;
}

/* ================MydModal=============  */
.modal-title {
  font-weight: 600;
  font-size: 23px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(---black)
}
